export const RESET_STATE_TYC = 'RESET_STATE_TYC'
export const LOADING_ALLTYC = 'LOADING_ALLTYC'
export const GET_ALLTYC = 'GET_ALLTYC'
export const GET_ERROR_ALLTYC = 'GET_ERROR_ALLTYC'
export const LOADING_SAVETYC = 'LOADING_SAVETYC'
export const GET_SAVETYC = 'GET_SAVETYC'
export const GET_ERROR_SAVETYC = 'GET_ERROR_SAVETYC'
export const LOADING_ALLFILTER = 'LOADING_ALLFILTER'
export const LOADING_ALLFILTER2 = 'LOADING_ALLFILTER2'
export const GET_ALLFILTER = 'GET_ALLFILTER'
export const GET_ERROR_ALLFILTER = 'GET_ERROR_ALLFILTER'