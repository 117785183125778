export const RESET_STATE_USER = 'RESET_STATE_USER'
export const LOADING_CREATEUSER = 'LOADING_CREATEUSER'
export const GET_CREATEUSER = 'GET_CREATEUSER'
export const GET_ERROR_CREATEUSER = 'GET_ERROR_CREATEUSER'
export const LOADING_USERFILTER = 'LOADING_USERFILTER'
export const GET_USERFILTER = 'GET_USERFILTER'
export const GET_ERROR_USERFILTER = 'GET_ERROR_USERFILTER'
export const LOADING_AGERANGE = 'LOADING_AGERANGE'
export const GET_AGERANGE = 'GET_AGERANGE'
export const GET_ERROR_AGERANGE = 'GET_ERROR_AGERANGE'
export const LOADING_TOTALUSER = 'LOADING_TOTALUSER'
export const GET_TOTALUSER = 'GET_TOTALUSER'
export const GET_ERROR_TOTALUSER = 'GET_ERROR_TOTALUSER'
export const LOADING_USERPRODUCTS = 'LOADING_USERPRODUCTS'
export const GET_USERPRODUCTS = 'GET_USERPRODUCTS'
export const GET_ERROR_USERPRODUCTS = 'GET_ERROR_USERPRODUCTS'