import {
    GET_ALLBENEFITS,
    GET_ALLPRODUCTS,
    GET_ALLPRODUCTSFAMILIES, GET_CREATEBENEFIT,
    GET_CREATEPRODUCTS,
    GET_ERROR_ALLBENEFITS,
    GET_ERROR_ALLPRODUCTS,
    GET_ERROR_ALLPRODUCTSFAMILIES, GET_ERROR_CREATEBENEFIT,
    GET_ERROR_CREATEPRODUCTS,
    GET_ERROR_UPLOADFILE, GET_UPLOADFILE,
    LOADING_ALLBENEFITS,
    LOADING_ALLPRODUCTS,
    LOADING_ALLPRODUCTSFAMILIES, LOADING_CREATEBENEFIT,
    LOADING_CREATEPRODUCTS, LOADING_UPLOADFILE,
    RESET_STATE_PRODUCTS,
    GET_ERROR_ALLFAMILYPRODUCT,
    GET_ALLFAMILYPRODUCT,
    LOADING_ALLFAMILYPRODUCT,
    GET_ERROR_CREATEFAMILYPRODUCT,
    LOADING_CREATEFAMILYPRODUCT,
    GET_CREATEFAMILYPRODUCT,
    GET_ERROR_UPDATEPOLIZA,
    LOADING_UPDATEPOLIZA,
    GET_UPDATEPOLIZA
} from "../../actionsType/products/productsActionType"

const INITIAL_STATE = {
    loading_allProducts: false,
    error_allProducts: null,
    success_allProducts: null,
    loading_createProducts: false,
    error_createProducts: null,
    success_createProducts: null,
    loading_allProductsFamilies: false,
    error_allProductsFamilies: null,
    success_allProductsFamilies: null,
    loading_allBenefits: false,
    error_allBenefits: null,
    success_allBenefits: null,
    loading_uploadFile: false,
    error_uploadFile: null,
    success_uploadFile: null,
    loading_createBenefit: false,
    error_createBenefit: null,
    success_createBenefit: null,
    loading_allFamilyProduct: false,
    error_allFamilyProduct: null,
    success_allFamilyProduct: null,
    loading_createFamilyProduct: false,
    error_createFamilyProduct: null,
    success_createFamilyProduct: null,
    loading_updatePoliza: false,
    error_updatePoliza: null,
    success_updatePoliza: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_PRODUCTS:
            return {
                ...state,
                loading_allProducts: false,
                error_allProducts: null,
                loading_createProducts: false,
                error_createProducts: null,
                success_createProducts: null,
                loading_allProductsFamilies: false,
                error_allProductsFamilies: null,
                loading_allBenefits: false,
                error_allBenefits: null,
                loading_uploadFile: false,
                error_uploadFile: null,
                success_uploadFile: null,
                loading_createBenefit: false,
                error_createBenefit: null,
                success_createBenefit: null,
                loading_allFamilyProduct: false,
                error_allFamilyProduct: null,
                loading_createFamilyProduct: false,
                error_createFamilyProduct: null,
                success_createFamilyProduct: null,
                loading_updatePoliza: false,
                error_updatePoliza: null,
                success_updatePoliza: null
            }

        case GET_UPDATEPOLIZA:
            return {
                ...state,
                loading_updatePoliza: false,
                error_updatePoliza: null,
                success_updatePoliza: action.payload
            }
        case LOADING_UPDATEPOLIZA:
            return {
                ...state,
                loading_updatePoliza: true,
                error_updatePoliza: null,
                success_updatePoliza: null
            }
        case GET_ERROR_UPDATEPOLIZA:
            return {
                ...state,
                error_updatePoliza: action.payload,
                loading_updatePoliza: false
            }

        case GET_CREATEFAMILYPRODUCT:
            return {
                ...state,
                loading_createFamilyProduct: false,
                error_createFamilyProduct: null,
                success_createFamilyProduct: action.payload
            }
        case LOADING_CREATEFAMILYPRODUCT:
            return {
                ...state,
                loading_createFamilyProduct: true,
                error_createFamilyProduct: null,
                success_createFamilyProduct: null
            }
        case GET_ERROR_CREATEFAMILYPRODUCT:
            return {
                ...state,
                error_createFamilyProduct: action.payload,
                loading_createFamilyProduct: false
            }

        case GET_ALLFAMILYPRODUCT:
            return {
                ...state,
                loading_allFamilyProduct: false,
                error_allFamilyProduct: null,
                success_allFamilyProduct: action.payload
            }
        case LOADING_ALLFAMILYPRODUCT:
            return {
                ...state,
                loading_allFamilyProduct: true,
                error_allFamilyProduct: null,
                success_allFamilyProduct: null
            }
        case GET_ERROR_ALLFAMILYPRODUCT:
            return {
                ...state,
                error_allFamilyProduct: action.payload,
                loading_allFamilyProduct: false
            }


        case GET_CREATEBENEFIT:
            return {
                ...state,
                loading_createBenefit: false,
                error_createBenefit: null,
                success_createBenefit: action.payload
            }
        case LOADING_CREATEBENEFIT:
            return {
                ...state,
                loading_createBenefit: true,
                error_createBenefit: null,
                success_createBenefit: null
            }
        case GET_ERROR_CREATEBENEFIT:
            return {
                ...state,
                error_createBenefit: action.payload,
                loading_createBenefit: false
            }

        case GET_UPLOADFILE:
            return {
                ...state,
                loading_uploadFile: false,
                error_uploadFile: null,
                success_uploadFile: action.payload
            }
        case LOADING_UPLOADFILE:
            return {
                ...state,
                loading_uploadFile: true,
                error_uploadFile: null,
                success_uploadFile: null
            }
        case GET_ERROR_UPLOADFILE:
            return {
                ...state,
                error_uploadFile: action.payload,
                loading_uploadFile: false
            }

        case GET_ALLBENEFITS:
            return {
                ...state,
                loading_allBenefits: false,
                error_allBenefits: null,
                success_allBenefits: action.payload
            }
        case LOADING_ALLBENEFITS:
            return {
                ...state,
                loading_allBenefits: true,
                error_allBenefits: null,
                success_allBenefits: null
            }
        case GET_ERROR_ALLBENEFITS:
            return {
                ...state,
                error_allBenefits: action.payload,
                loading_allBenefits: false
            }


        case GET_ALLPRODUCTSFAMILIES:
            return {
                ...state,
                loading_allProductsFamilies: false,
                error_allProductsFamilies: null,
                success_allProductsFamilies: action.payload
            }
        case LOADING_ALLPRODUCTSFAMILIES:
            return {
                ...state,
                loading_allProductsFamilies: true,
                error_allProductsFamilies: null,
                success_allProductsFamilies: null
            }
        case GET_ERROR_ALLPRODUCTSFAMILIES:
            return {
                ...state,
                error_allProductsFamilies: action.payload,
                loading_allProductsFamilies: false
            }

        case GET_ALLPRODUCTS:
            return {
                ...state,
                loading_allProducts: false,
                error_allProducts: null,
                success_allProducts: action.payload
            }
        case LOADING_ALLPRODUCTS:
            return {
                ...state,
                loading_allProducts: true,
                error_allProducts: null,
                success_allProducts: null
            }
        case GET_ERROR_ALLPRODUCTS:
            return {
                ...state,
                error_allProducts: action.payload,
                loading_allProducts: false
            }

        case GET_CREATEPRODUCTS:
            return {
                ...state,
                loading_createProducts: false,
                error_createProducts: null,
                success_createProducts: action.payload
            }
        case LOADING_CREATEPRODUCTS:
            return {
                ...state,
                loading_createProducts: true,
                error_createProducts: null,
                success_createProducts: null
            }
        case GET_ERROR_CREATEPRODUCTS:
            return {
                ...state,
                error_createProducts: action.payload,
                loading_createProducts: false
            }
        default:
            return state
    }
}
