import {
    RESET_STATE_USER,
    GET_ERROR_CREATEUSER,
    GET_CREATEUSER,
    LOADING_CREATEUSER,
    GET_ERROR_USERFILTER,
    GET_USERFILTER,
    LOADING_USERFILTER,
    GET_ERROR_AGERANGE,
    GET_AGERANGE,
    LOADING_AGERANGE,
    LOADING_TOTALUSER,
    GET_TOTALUSER,
    GET_ERROR_TOTALUSER,
    LOADING_USERPRODUCTS,
    GET_USERPRODUCTS,
    GET_ERROR_USERPRODUCTS
} from "../../actionsType/user/userActionType"


const INITIAL_STATE = {
    loading_createUser: false,
    error_createUser: null,
    success_createUser: null,
    loading_userFilter: false,
    error_userFilter: null,
    success_userFilter: null,
    loading_totalUser: false,
    error_totalUser: null,
    success_totalUser: 5,
    loading_productsUser: false,
    error_productsUser: null,
    success_productsUser: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_USER:
            return {
                ...state,
                loading_createUser: false,
                error_createUser: null,
                success_createUser: null,
                loading_userFilter: false,
                error_userFilter: null,
                error_totalUser: null,
                loading_totalUser: false,
                loading_ageRange: false,
                error_ageRange: null,
                loading_productsUser: false,
                error_productsUser: null
            }
        case GET_USERFILTER:
            return {
                ...state,
                loading_userFilter: false,
                error_userFilter: null,
                success_userFilter: action.payload
            }
        case LOADING_USERFILTER:
            return {
                ...state,
                loading_userFilter: true,
                error_userFilter: null,
                success_userFilter: null
            }
        case GET_ERROR_USERFILTER:
            return {
                ...state,
                error_userFilter: action.payload,
                loading_userFilter: false
            }

        case GET_CREATEUSER:
            return {
                ...state,
                loading_createUser: false,
                error_createUser: null,
                success_createUser: action.payload
            }
        case LOADING_CREATEUSER:
            return {
                ...state,
                loading_createUser: true,
                error_createUser: null,
                success_createUser: null
            }
        case GET_ERROR_CREATEUSER:
            return {
                ...state,
                error_createUser: action.payload,
                loading_createUser: false
            }

        case GET_AGERANGE:
            return {
                ...state,
                loading_ageRange: false,
                error_ageRange: null,
                success_ageRange: action.payload
            }
        case LOADING_AGERANGE:
            return {
                ...state,
                loading_ageRange: true,
                error_ageRange: null,
                success_ageRange: null
            }
        case GET_ERROR_AGERANGE:
            return {
                ...state,
                error_ageRange: action.payload,
                loading_ageRange: false
            }

        case GET_TOTALUSER:
            return {
                ...state,
                loading_totalUser: false,
                error_totalUser: null,
                success_totalUser: action.payload
            }
        case LOADING_TOTALUSER:
            return {
                ...state,
                loading_totalUser: true,
                error_totalUser: null,
                success_totalUser: null
            }
        case GET_ERROR_TOTALUSER:
            return {
                ...state,
                error_totalUser: action.payload,
                loading_totalUser: false
            }
        case GET_USERPRODUCTS:
            return {
                ...state,
                loading_productsUser: false,
                error_productsUser: null,
                success_productsUser: action.payload
            }
        case LOADING_USERPRODUCTS:
            return {
                ...state,
                loading_productsUser: true,
                error_productsUser: null,
                success_productsUser: null
            }
        case GET_ERROR_USERPRODUCTS:
            return {
                ...state,
                error_productsUser: action.payload,
                loading_productsUser: false
            }
        default:
            return state
    }
}
