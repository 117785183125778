export const RESET_STATE_DASHBOARD = 'RESET_STATE_DASHBOARD'
export const LOADING_COUNTPOINTS = 'LOADING_COUNTPOINTS'
export const GET_COUNTPOINTS = 'GET_COUNTPOINTS'
export const GET_ERROR_COUNTPOINTS = 'GET_ERROR_COUNTPOINTS'
export const GET_COUNTLOGIN = 'GET_COUNTLOGIN'
export const LOADING_COUNTLOGIN = 'LOADING_COUNTLOGIN'
export const GET_ERROR_COUNTLOGIN = 'GET_ERROR_COUNTLOGIN'
export const SET_VALUESKIN = 'SET_VALUESKIN'
export const LOADING_COUNTUSERS = 'LOADING_COUNTUSERS'
export const GET_COUNTUSERS = 'GET_COUNTUSERS'
export const GET_ERROR_COUNTUSERS = 'GET_ERROR_COUNTUSERS'
export const LOADING_CINTASACC = 'LOADING_CINTASACC'
export const GET_CINTASACC = 'GET_CINTASACC'
export const GET_ERROR_CINTASACC = 'GET_ERROR_CINTASACC'
export const LOADING_TOTALPRODUCT = 'LOADING_TOTALPRODUCT'
export const GET_TOTALPRODUCT = 'GET_TOTALPRODUCT'
export const GET_ERROR_TOTALPRODUCT = 'GET_ERROR_TOTALPRODUCT'