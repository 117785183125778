import {
    GET_COUNTPOINTS, GET_COUNTUSERS, GET_COUNTLOGIN,
    GET_ERROR_COUNTPOINTS, GET_ERROR_COUNTUSERS, GET_ERROR_COUNTLOGIN,
    LOADING_COUNTPOINTS, LOADING_COUNTUSERS, LOADING_COUNTLOGIN,
    RESET_STATE_DASHBOARD, SET_VALUESKIN, LOADING_CINTASACC, GET_CINTASACC, GET_ERROR_CINTASACC, GET_ERROR_TOTALPRODUCT,
    GET_TOTALPRODUCT, LOADING_TOTALPRODUCT
} from "../../actionsType/dashboard/dashboardActionType"

const INITIAL_STATE = {
    loading_countPoint: false,
    error_countPoint: null,
    success_countPoint: null,
    loading_countLogin: false,
    error_countLogin: null,
    success_countLogin: null,
    valueSkin: null,
    loading_countUser: false,
    error_countUser: null,
    success_countUser: null,
    loading_cintasAcc: false,
    error_cintasAcc: null,
    success_cintasAcc: null,
    loading_totalProduct: false,
    error_totalProduct: null,
    success_totalProduct: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_DASHBOARD:
            return {
                ...state,
                loading_countPoint: false,
                error_countPoint: null,
                loading_countLogin: false,
                error_countLogin: null,
                loading_countUser: false,
                error_countUser: null,
                loading_cintasAcc: false,
                error_cintasAcc: null,
                loading_totalProduct: false,
                error_totalProduct: null
            }

        case GET_TOTALPRODUCT:
            return {
                ...state,
                loading_totalProduct: false,
                error_totalProduct: null,
                success_totalProduct: action.payload
            }
        case LOADING_TOTALPRODUCT:
            return {
                ...state,
                loading_totalProduct: true,
                error_totalProduct: null,
                success_totalProduct: null
            }
        case GET_ERROR_TOTALPRODUCT:
            return {
                ...state,
                error_totalProduct: action.payload,
                loading_totalProduct: false
            }
        case GET_CINTASACC:
            return {
                ...state,
                loading_cintasAcc: false,
                error_cintasAcc: null,
                success_cintasAcc: action.payload
            }
        case LOADING_CINTASACC:
            return {
                ...state,
                loading_cintasAcc: true,
                error_cintasAcc: null,
                success_cintasAcc: null
            }
        case GET_ERROR_CINTASACC:
            return {
                ...state,
                error_cintasAcc: action.payload,
                loading_cintasAcc: false
            }

        case GET_COUNTPOINTS:
            return {
                ...state,
                loading_countPoint: false,
                error_countPoint: null,
                success_countPoint: action.payload
            }
        case LOADING_COUNTPOINTS:
            return {
                ...state,
                loading_countPoint: true,
                error_countPoint: null,
                success_countPoint: null
            }
        case GET_ERROR_COUNTPOINTS:
            return {
                ...state,
                error_countPoint: action.payload,
                loading_countPoint: false
            }

        case GET_COUNTUSERS:
            return {
                ...state,
                loading_countUser: false,
                error_countUser: null,
                success_countUser: action.payload
            }
        case LOADING_COUNTUSERS:
            return {
                ...state,
                loading_countUser: true,
                error_countUser: null,
                success_countUser: null
            }
        case GET_ERROR_COUNTUSERS:
            return {
                ...state,
                error_countUser: action.payload,
                loading_countUser: false
            }

        case SET_VALUESKIN:
            return {
                ...state,
                valueSkin: action.payload
            }

        case GET_COUNTLOGIN:
            return {
                ...state,
                loading_countLogin: false,
                error_countLogin: null,
                success_countLogin: action.payload
            }
        case LOADING_COUNTLOGIN:
            return {
                ...state,
                loading_countLogin: true,
                error_countLogin: null,
                success_countLogin: null
            }
        case GET_ERROR_COUNTLOGIN:
            return {
                ...state,
                error_countLogin: action.payload,
                loading_countLogin: false
            }
        default:
            return state
    }
}
