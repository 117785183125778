import {
    RESET_STATE_QUESTION,
    GET_ALLQUESTION,
    GET_ERROR_ALLQUESTION,
    LOADING_ALLQUESTION,
    GET_ERROR_SAVEQUESTION, GET_SAVEQUESTION, LOADING_SAVEQUESTION
} from "../../actionsType/questionFreq/questionActionType"


const INITIAL_STATE = {
    loading_allquestion: false,
    error_allquestion: null,
    success_allquestion: null,
    loading_savequestion: false,
    error_savequestion: null,
    success_savequestion: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_QUESTION:
            return {
                ...state,
                loading_allquestion: false,
                error_allquestion: null,
                loading_savequestion: false,
                error_savequestion: null,
                success_savequestion: null
            }

        case GET_ALLQUESTION:
            return {
                ...state,
                loading_allquestion: false,
                error_allquestion: null,
                success_allquestion: action.payload
            }
        case LOADING_ALLQUESTION:
            return {
                ...state,
                loading_allquestion: true,
                error_allquestion: null,
                success_allquestion: null
            }
        case GET_ERROR_ALLQUESTION:
            return {
                ...state,
                error_allquestion: action.payload,
                loading_allquestion: false
            }

        case GET_SAVEQUESTION:
            return {
                ...state,
                loading_savequestion: false,
                error_savequestion: null,
                success_savequestion: action.payload
            }
        case LOADING_SAVEQUESTION:
            return {
                ...state,
                loading_savequestion: true,
                error_savequestion: null,
                success_savequestion: null
            }
        case GET_ERROR_SAVEQUESTION:
            return {
                ...state,
                error_savequestion: action.payload,
                loading_savequestion: false
            }

        default:
            return state
    }
}
