// ** Redux Imports
import {combineReducers} from 'redux'

// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import authReducer from "./auth/authReducer"
import dashboardReducer from "./dashboard/dashboardReducer"
import productsReducer from "./products/productsReducer"
import userReducer from "./user/userReducer"
import tycReducer from "./tyc/tycReducer"
import questionReducer from "./questionFreq/questionReducer"

const rootReducer = combineReducers({
    navbar,
    layout,
    authReducer,
    dashboardReducer,
    productsReducer,
    userReducer,
    tycReducer,
    questionReducer
})

export default rootReducer
