export const RESET_STATE_PRODUCTS = 'RESET_STATE_PRODUCTS'
export const LOADING_ALLPRODUCTS = 'LOADING_ALLPRODUCTS'
export const GET_ALLPRODUCTS = 'GET_ALLPRODUCTS'
export const GET_ERROR_ALLPRODUCTS = 'GET_ERROR_ALLPRODUCTS'
export const LOADING_CREATEPRODUCTS = 'LOADING_CREATEPRODUCTS'
export const GET_CREATEPRODUCTS = 'GET_CREATEPRODUCTS'
export const GET_ERROR_CREATEPRODUCTS = 'GET_ERROR_CREATEPRODUCTS'
export const LOADING_ALLPRODUCTSFAMILIES = 'LOADING_ALLPRODUCTSFAMILIES'
export const GET_ALLPRODUCTSFAMILIES = 'GET_ALLPRODUCTSFAMILIES'
export const GET_ERROR_ALLPRODUCTSFAMILIES = 'GET_ERROR_ALLPRODUCTSFAMILIES'
export const LOADING_ALLBENEFITS = 'LOADING_ALLBENEFITS'
export const GET_ALLBENEFITS = 'GET_ALLBENEFITS'
export const GET_ERROR_ALLBENEFITS = 'GET_ERROR_ALLBENEFITS'
export const LOADING_UPLOADFILE = 'LOADING_UPLOADFILE'
export const GET_UPLOADFILE = 'GET_UPLOADFILE'
export const GET_ERROR_UPLOADFILE = 'GET_ERROR_UPLOADFILE'
export const LOADING_CREATEBENEFIT = 'LOADING_CREATEBENEFIT'
export const GET_CREATEBENEFIT = 'GET_CREATEBENEFIT'
export const GET_ERROR_CREATEBENEFIT = 'GET_ERROR_CREATEBENEFIT'
export const LOADING_ALLFAMILYPRODUCT = 'LOADING_ALLFAMILYPRODUCT'
export const GET_ALLFAMILYPRODUCT = 'GET_ALLFAMILYPRODUCT'
export const GET_ERROR_ALLFAMILYPRODUCT = 'GET_ERROR_ALLFAMILYPRODUCT'
export const LOADING_CREATEFAMILYPRODUCT = 'LOADING_CREATEFAMILYPRODUCT'
export const GET_CREATEFAMILYPRODUCT = 'GET_CREATEFAMILYPRODUCT'
export const GET_ERROR_CREATEFAMILYPRODUCT = 'GET_ERROR_CREATEFAMILYPRODUCT'
export const LOADING_UPDATEPOLIZA = 'LOADING_UPDATEPOLIZA'
export const GET_UPDATEPOLIZA = 'GET_UPDATEPOLIZA'
export const GET_ERROR_UPDATEPOLIZA = 'GET_ERROR_UPDATEPOLIZA'