/**
 * @author Angel Ruiz <angel.ichazu@gmail.com>
 * 06-Dic-2021
 */
import {
    ERROR,
    RESET_STATE_AUTH,
    SUCCESS_PROCESS,
    UPDATE_TOKEN_LOGIN
} from "../../actionsType/auth/authActionsType"

const INITIAL_STATE = {
    errorProcess: null,
    successProcess: false,
    tokenLogin: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_AUTH:
            return {
                ...state,
                errorProcess: null,
                successProcess: false
            }
        case UPDATE_TOKEN_LOGIN:
            return {
                ...state,
                tokenLogin: action.payload,
                errorProcess: null
            }
        case ERROR:
            return {
                ...state,
                errorProcess: action.payload
            }
        case SUCCESS_PROCESS:
            return {
                ...state,
                successProcess: action.payload || true
            }
        default:
            return state
    }
}
