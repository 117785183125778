import {
    RESET_STATE_TYC,
    GET_ALLTYC,
    GET_ERROR_ALLTYC,
    LOADING_ALLTYC,
    GET_ERROR_SAVETYC,
    GET_SAVETYC,
    LOADING_SAVETYC,
    GET_ERROR_ALLFILTER,
    GET_ALLFILTER,
    LOADING_ALLFILTER,
    LOADING_ALLFILTER2
} from "../../actionsType/tyc/tycActionType"


const INITIAL_STATE = {
    loading_allTyc: false,
    error_allTyc: null,
    success_allTyc: null,
    loading_saveTyc: false,
    error_saveTyc: null,
    success_saveTyc: null,
    loading_allFilter: false,
    error_allFilter: null,
    success_allFilter: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_STATE_TYC:
            return {
                ...state,
                loading_allTyc: false,
                error_allTyc: null,
                loading_saveTyc: false,
                error_saveTyc: null,
                success_saveTyc: null,
                loading_allFilter: false,
                loading_allFilter2: false,
                error_allFilter: null,
                success_allFilter: null
            }

        case GET_ALLFILTER:
            return {
                ...state, loading_allFilter: false, loading_allFilter2: false, error_allFilter: null, success_allFilter: action.payload
            }
        case LOADING_ALLFILTER:
            return {
                ...state, loading_allFilter: true, error_allFilter: null, success_allFilter: null
            }
        case LOADING_ALLFILTER2:
            return {
                ...state, loading_allFilter2: true, error_allFilter: null, success_allFilter: null
            }
        case GET_ERROR_ALLFILTER:
            return {
                ...state, error_allFilter: action.payload, loading_allFilter: false, loading_allFilter2: false
            }


        case GET_ALLTYC:
            return {
                ...state, loading_allTyc: false, error_allTyc: null, success_allTyc: action.payload
            }
        case LOADING_ALLTYC:
            return {
                ...state, loading_allTyc: true, error_allTyc: null, success_allTyc: null
            }
        case GET_ERROR_ALLTYC:
            return {
                ...state, error_allTyc: action.payload, loading_allTyc: false
            }

        case GET_SAVETYC:
            return {
                ...state, loading_saveTyc: false, error_saveTyc: null, success_saveTyc: action.payload
            }
        case LOADING_SAVETYC:
            return {
                ...state, loading_saveTyc: true, error_saveTyc: null, success_saveTyc: null
            }
        case GET_ERROR_SAVETYC:
            return {
                ...state, error_saveTyc: action.payload, loading_saveTyc: false
            }

        default:
            return state
    }
}
